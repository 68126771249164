import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Heading from 'theme-ui'


const styles = {
  count: {
    fontSize: 4
  },
  subheader: {
    fontWeight: `body`,
    color: `omegaDark`
  },
  runninghead: {
    fontWeight: `body`,
    color: `omegaDark`,
    mb: 0
  }
}

const Projekt = props => (
  <Layout {...props}>
    <Seo title='Projektbeschrieb' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header="Projektbeschrieb"
        
          />
        </Section>
        <Section>
          In den nächsten 3 Jahren wird an den Schulen Grauholz mit bis zu 10% mehr Schüler*Innen gerechnet.
        </Section>
        <Divider />
        <Section>

          Die Arbeitsgruppe, bestehend aus Mitgliedern der Schule, Schulkommision und Behörden will zusammen mit der Bevölkerung mögliche Strategien entwickeln um der zu erwarteten Zuwachs gerecht zu werden.

        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Projekt
